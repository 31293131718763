.advisoryCommitteeSection {
  background-color: var(--new-bg-color);
}

.batchRepresentativeSection {
  background-color: var(--primary-text-color);
}

.teamMembersHeading {
  padding: 4rem 0rem 0rem;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  font-family: var(--new-primary-font);
  color: var(--light-text-primary);
}

.teamMemberCards {
  display: flex;
  gap: 2rem;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  padding: 3rem 10rem 4rem;
}

.finalYearSection {
  padding: 3rem 10rem 0rem;
}

.preFinalYearSection {
  padding: 2rem 10rem 4rem;
}

@media (max-width: 1300px) {
  .teamMemberCards {
    gap: 1rem;
  }

  .preFinalYearSection {
    padding: 2rem 5rem 4rem;
  }
}

@media (max-width: 1100px) {
  .teamMemberCards {
    gap: 2rem;
  }

  .preFinalYearSection {
    padding: 2rem 10rem 4rem;
  }
}

@media (max-width: 700px) {
  .teamMembersHeading {
    padding: 2.25rem 1.25rem 0rem 1.25rem;
  }

  .teamMemberCards {
    padding: 2.25rem 0.875rem;
    gap: 1rem;
  }

  .alumniSection {
    padding: 2.25rem 0.875rem 0rem 0.875rem;
  }

  .advisoryCommittee {
    padding: 1rem 0.875rem 2.25rem 0.875rem;
  }
}
