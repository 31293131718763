.homepageHeadings {
  color: var(--dark-text-primary, #fff);
  font-size: 2.5rem;
  font-weight: 500;
}

.eventsSectionHome {
  font-family: var(--new-primary-font);
  line-height: normal;
  font-style: normal;
  display: flex;
  padding: 3rem 10rem;
  flex-direction: column;
  align-items: center;
  background: var(--surface-dark-primary);
}

.eventsContainerHome {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.25rem;
}

.eventCardContainerHome {
  display: flex;
  padding-left: 1rem;
  justify-content: space-between;
  width: 70rem;
}

.customButton {
  color: var(--primary-accent-color);
  font-size: 1.25rem;
  font-weight: 600;
}

@media screen and (max-width: 1198px) {
  .eventCardContainerHome {
    width: 60rem;
    padding-left: 5.5rem;
    gap: 1rem;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 998px) {
  .eventCardContainerHome {
    width: 46rem;
    gap: 1rem;
    padding-left: 0.6rem;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 798px) {
  .eventCardContainerHome {
    width: 35rem;
    padding-left: 1.55rem;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 620px) {
  .eventCardContainerHome {
    width: 32rem;
    padding-left: 0.6rem;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 576px) {
  .eventCardContainerHome {
    width: 23rem;
    padding-left: 4rem;
    flex-wrap: wrap;
  }

  .customButton {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 415px) {
  .eventCardContainerHome {
    width: 17rem;
    padding-left: 1.1rem;
    flex-wrap: wrap;
  }

  .homepageHeadings {
    color: var(--dark-text-primary, #fff);
    font-size: 2rem;
  }
}
