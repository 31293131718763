.home-container {
  background: var(--surface-dark-primary, #02071b);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.home-right-container {
  display: flex;
  height: 52.25rem;
  width: 60%;
}

.home-left-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-style: normal;
  line-height: normal;
  font-family: var(--new-primary-font);
  gap: 6.5rem;
  box-sizing: border-box;
  margin-left: 7.5rem;
  margin-top: 9.5rem;
  margin-bottom: 12.12rem;
  width: 40%;
}

.home-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.home-header-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.home-college-name {
  color: var(--s-dark-text-tertiary, #d2d1d6);
  align-self: stretch;
  font-size: 1.75rem;
  font-weight: 400;
}

.home-aasf-title {
  color: var(--s-dark-text-primary, #f4f4f5);
  align-self: stretch;
  font-size: 2.5rem;
  font-weight: 500;
  width: 34rem;
}

.home-about-aasf {
  color: var(--s-dark-text-secondary, #e7e7ea);
  align-self: stretch;
  font-size: 1rem;
  font-weight: 400;
}

.home-button-container {
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
}

.events-button {
  display: flex;
  height: 3rem;
  padding: 0rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.375rem;
  border: 1px solid var(--surface-cta, #ffcd25);
  background: var(--surface-cta, #ffcd25);
  color: var(--text-cta, #1d1934);
  font-size: 1rem;
  font-weight: 500;
  transition: all ease 0.5s;
}

.events-button:hover {
  transform: scale(1.1, 1.1);
  background-color: var(--new-primary-bg-color);
  color: var(--surface-cta, #ffcd25);
}

.contact-us-button {
  display: flex;
  height: 3rem;
  padding: 0rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.375rem;
  border: 2px solid var(--surface-cta, #ffcd25);
  background: var(--surface-cta-revert, #02071b);
  color: var(--surface-cta, #ffcd25);
  font-size: 1rem;
  transition: all ease 0.5s;
  font-weight: 500;
}

.contact-us-button:hover {
  transform: scale(1.1, 1.1);
  background: var(--surface-cta, #ffcd25);
  color: var(--text-cta, #1d1934);
}

.home-icon-container {
  transform: rotate(30deg);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.skills-icon {
  width: 5.00675rem;
  height: 5.00675rem;
}

.icon-holder {
  display: flex;
  max-width: 3.5rem;
  max-height: 3.5rem;
  margin: 2.2rem;
  padding: 1.7475rem 1.75rem 1.75838rem 1.75594rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1.33556rem;
  border: 0.668px solid #d9d9d9;
  background: linear-gradient(180deg, #6587ad 0%, #192843 100%);
  box-shadow: 0px 2.6711528301239014px 26.711530685424805px 0px
    rgba(9, 9, 12, 0.1);
}

@media only screen and (max-width: 1308px) {
  .icon-holder {
    width: 3rem;
    height: 3rem;
    margin: 1.9rem;
  }
  .home-left-container {
    width: 40%;
  }
}

@media only screen and (max-width: 768px) {
  .home-container {
    display: flex;
    flex-direction: column-reverse;
    height: 92vh;
    padding-bottom: 3rem;
  }
  .row-1 {
    display: none;
  }
  .icon-holder {
    width: 2.5rem;
    height: 2.8rem;
    margin: 1rem;
    display: flex;
    padding: 1.24513rem 1.22975rem 1.21238rem 1.22769rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  .skills-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
  .home-aasf-title {
    width: 80%;
  }
  .home-left-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.25rem;
    margin-left: 2rem;
    margin-right: 1rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    width: 100%;
  }
  .home-right-container {
    width: 70rem;
  }
  .home-text-container {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }
  .home-header-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;
  }
  .home-college-name {
    color: var(--s-dark-text-tertiary, #d2d1d6);
    font-family: Poppins;
    font-size: 1.25rem;
    font-weight: 400;
  }
  .home-aasf-title {
    align-self: stretch;
    color: var(--s-dark-text-primary, #f4f4f5);
    font-size: 1.75rem;
    font-weight: 500;
  }
  .home-about-aasf {
    color: var(--s-dark-text-secondary, #e7e7ea);
    align-self: stretch;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
  .home-button-container {
    display: flex;
    align-items: flex-start;
    gap: 1.25rem;
  }
  .events-button,
  .contact-us-button {
    display: flex;
    height: 2.5rem;
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
  }
  .events-button {
    border: 1px solid var(--surface-cta, #ffcd25);
    background: var(--surface-cta, #ffcd25);
    color: var(--surface-dark-primary, #02071b);
  }
  .contact-us-button {
    border: 2px solid var(--surface-cta, #ffcd25);
    background: var(--surface-cta-revert, #02071b);
    color: var(--surface-cta, #ffcd25);
  }
}
