@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300&display=swap');

:root {
  --primary-bg-color: #000000;
  --secondary-bg-color: #2a2a2b;
  --primary-accent-color: #ffcd25;
  --secondary-accent-color: #d4b2e4;
  --primary-text-color: #ffffff;
  --secondary-text-color: #e9e8e8;
  --carousel-text-color: #444255;
  --primary-font: 'Work Sans';
  --secondary-font: 'Mulish';

  --primary-bg-color-new: #02071b;
  --secondary-bg-color-new: #1b2032;
  --secondary-font-new: 'Poppins';
  --heading-text-color-new: #f4f4f5;
  --primary-text-color-new: #a5a3ae;
  --secondary-text-color-new: #cccdd1;
  --input-text-color-new: #e7e7ea;
  --marquee-bg-color: #37ed81;
  --marquee-text-color: #1d1934;

  --new-primary-font: 'Poppins';
  --light-text-primary: #1d1934;
  --new-font-color: #1d1934;
  --primary-font-color: #777585;
  --secondary-font-color: #444255;
  --new-bg-color: #f9f9f9;

  --s-dark-text-tertiary: #d2d1d6;
  --s-dark-text-primary: #f4f4f5;
  --s-dark-text-secondary: #e7e7ea;
  --surface-dark-primary: #02071b;
  --dark-text-primary: #fff;
  --intro-text-color: #f4f4f5;
  --body-text-color: #e7e7ea;
  --new-primary-bg-color: #02071b;
  --navbar-backdrop: rgb(2, 7, 27, 0.8);
}

html {
  font-size: 16px;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ol,
ul,
li,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
footer,
header,
menu,
nav,
section,
audio,
video {
  margin: 0;
  padding: 0;
}

article,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

a {
  text-decoration: none;
  color: var(--secondary-text-color);
  transition: 0.4s;
}

a:hover {
  color: var(--primary-accent-color);
}

hr {
  display: block;
  height: 1px;
  border: 0px;
  border-top: 1px solid #000;
  margin: 0px;
  padding: 0px;
}

dl,
ol,
ul {
  list-style-type: none;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  font-size: 100%;
  padding: 0;
  touch-action: manipulation;
  user-select: none;
  -webkit-appearance: none;
}

/* Common CSS */

.flex-centre {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-scroll {
  overflow: hidden;
}

.primary-font {
  font-family: var(--primary-font);
  color: var(--primary-text-color);
}

.secondary-font {
  color: var(--secondary-text-color);
  font-family: var(--secondary-font-new);
}

.heading-font {
  color: var(--primary-accent-color);
  font-family: var(--primary-font);
  font-weight: 1000;
}

.vertical-font {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: var(--carousel-text-color);
  font-family: var(--secondary-font-new);
  font-size: 2.5rem;
  display: inline-block;
}
