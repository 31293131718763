.contact-us-page {
  padding: 7rem 25rem 4rem;
  background-image: url('https://res.cloudinary.com/dzerj4bzd/image/upload/v1661771074/AASF%20Website/contact-us-bg_aiwv2p.png');
  background-size: cover;
}

.google-map-button a {
  font-size: 1.1rem;
  color: var(--primary-accent-color);
  background-color: var(--primary-bg-color-new);
  padding: 0.75rem 2rem;
  border-radius: 0.375rem;
  width: fit-content;
  cursor: pointer;
}

.google-map-button {
  transition: 0.5s;
  position: absolute;
  top: 10rem;
  left: 1.875rem;
}

.google-map-button:hover {
  transform: scale(1.01);
}

@media screen and (max-width: 1700px) {
  .contact-us-page {
    padding: 7rem 12.4rem 4rem;
  }
  .google-map-button a {
    font-size: 0.9rem;
  }

  .contact-us-title-heading {
    font-size: 2.5rem;
    text-shadow: 0rem 0.0625rem, 0.0625rem 0px, 0.0625rem 0.0625rem currentColor;
    padding: 0.7rem 0rem;
  }

  .contact-us-title-motto {
    font-size: 1.8rem;
    padding: 0.7rem 0rem;
  }
}

@media screen and (max-width: 960px) {
  .contact-us-page {
    flex-direction: column;
    align-items: center;
    padding: 7.5rem 1rem 4rem;
  }

  .contact-us-title {
    transform: translate(0, 0);
    font-weight: 500;
  }

  .contact-us-page-right {
    padding-right: 0rem;
  }

  .google-map-button {
    padding: 1rem 0rem;
  }

  .contact-us-title-motto {
    font-size: 1.2rem;
    padding: 1rem 0rem;
  }

  .contact-us-page-right {
    padding: 1rem 0rem;
  }
}

@media screen and (max-width: 600px) {
  .contact-us-page {
    padding: 7rem 1rem 4rem;
  }

  .contact-us-title-motto {
    font-size: 1rem;
    padding: 1rem 0rem;
  }
  .google-map-button {
    top: 7.25rem;
    left: 1rem;
  }
}
