.teamMemberCard {
  height: auto;
  width: 10rem;
  gap: 1.25rem;
  overflow: hidden;
  text-align: center;
}

.teamMemberCard:hover {
  transform: scale(1.02);
}

.teamMemberCard__image img {
  height: 8.75rem;
  width: 8.75rem;
  padding: 1rem 0rem 1.25rem;
  border-radius: 50%;
  object-fit: cover;
}

.teamMemberCard__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  padding: 0rem 0rem 1.25rem;
  gap: 0.5rem;
}

.teamMemberCard__name {
  color: var(--new-font-color);
  font-size: 1rem;
  font-weight: 500;
  font-family: var(--new-primary-font);
}

.teamMemberCard__year {
  font-size: 0.8rem;
  color: var(--primary-font-color);
  font-weight: 400;
  font-family: var(--new-primary-font);
}

.teamMemberCard__domain {
  font-size: 0.8rem;
  color: var(--secondary-font-color);
  font-weight: 400;
  font-family: var(--new-primary-font);
}

.teamCard__socialicon {
  padding: 0px 9.6px;
  height: auto;
  width: 1.5rem;
  color: var(--primary-font-color);
}

.teammemberCard__socialIcons a:hover {
  color: var(--primary-accent-color);
}

.socialicons-designation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  padding: 0rem 0rem 1rem;
}

.teammemberCard__socialIcons {
  gap: 0.5rem;
}

@media (max-width: 700px) {
  .teamMemberCard {
    height: auto;
    width: 9.75rem;
  }

  .teamMemberCard__image img {
    height: 7.75rem;
    width: 7.75rem;
    padding: 0.875rem 0rem 1rem;
  }

  .teamMemberCard__info {
    gap: 4px;
    padding: 0px 0px 16px;
  }

  .teamMemberCard__name {
    font-size: 0.875rem;
  }

  .teamMemberCard__year {
    font-size: 0.75rem;
  }

  .teamMemberCard__domain {
    font-size: 0.75rem;
  }

  .socialicons-designation {
    padding: 0rem 0rem 0.875rem;
  }
}
