.introSectionHome {
  padding: 3rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--new-bg-color);
  gap: 0.625rem;
}

.introSectionItems {
  font-family: var(--new-primary-font);
  line-height: normal;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
  max-width: 70rem;
}

.introSectionContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
  text-align: left;
}

.introSectionHeading {
  color: var(--light-text-primary);
  font-size: 2.5rem;
  font-weight: 500;
}

.introSectionText {
  font-size: 1rem;
  font-weight: 400;
  align-self: stretch;
  max-width: 70rem;
}

.introSectionCardsContainer {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  width: 70rem;
}

.introSectionCards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 16rem;
  height: 16.75rem;
  border-radius: 0.75rem;
  border: 0.03rem solid #000;
  object-fit: cover;
  gap: 1rem;
}

.AASFSectionIcon {
  height: 3rem;
  width: 3rem;
}

.introSectionCardText {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
}

@media screen and (max-width: 1200px) {
  .introSectionCardsContainer {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1200px) {
  .introSectionItems {
    gap: 1.5rem;
    max-width: 100%;
  }

  .introSectionContent {
    gap: 1.5rem;
  }

  .introSectionText {
    max-width: 100%;
  }

  .introSectionCardsContainer {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .introSectionCards {
    width: 10rem;
    height: 10rem;
    gap: 0.7rem;
  }

  .introSectionCardText {
    font-size: 0.8rem;
  }

  .AASFSectionIcon {
    height: 2rem;
    width: 2rem;
  }
}

@media screen and (max-width: 400px) {
  .introSectionCards {
    width: 8.5rem;
    height: 8.5rem;
    gap: 0.5rem;
    text-align: center;
  }

  .introSectionCardText {
    font-size: 0.8rem;
    padding: 0rem 0.7rem;
  }
}
