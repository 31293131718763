.new-event-card {
  display: flex;
  width: 22rem;
  height: 30rem;
  padding-bottom: 1rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 1px solid var(--grey-5, #d2d1d6);
  background: var(--grey-white, #fff);
}
.event-image-container {
  display: flex;
  height: 16.5rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
}

.event-image {
  width: 22rem;
  height: 16.5rem;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
}

.event-details-container {
  display: flex;
  padding: 0rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.125rem;
  align-self: stretch;
  height: 15rem;
}

.event-details-header {
  align-self: stretch;
  color: var(--light-text-primary, #1d1934);

  /* mobile/event/title */
  font-family: var(--secondary-font-new);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.event-details {
  color: var(--light-text-secondary, #444255);
  font-family: var(--primary-font);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
}

@media only screen and (max-width: 768px) {
  .new-event-card {
    width: 15.125rem;
    height: auto;
  }

  .event-image-container {
    height: 11rem;
  }

  .event-image {
    width: 15.125rem;
    height: 11rem;
  }

  .event-details-header {
    font-size: 1.125rem;
  }
  .event-details-container {
    gap: 0.875rem;
    height: auto;
  }
  .event-details {
    font-size: 0.875rem;
  }
}
