.footer {
  background-color: var(--primary-bg-color-new);
  padding: 5rem 15rem;
  font-family: var(--secondary-font-new);
}

.footer-container {
  font-size: 1.2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.footer-address-container {
  display: flex;
}

.footer-menu-container {
  display: flex;
  justify-content: space-between;
}

.footer-address-Image {
  padding-right: 2rem;
}

.footer-address-text {
  color: var(--primary-text-color-new);
  font-weight: 400;
  display: flex;
  flex-direction: column;
}

.footer-menu {
  padding-right: 3.5rem;
}

.footer-menu-heading {
  padding-bottom: 1rem;
  font-weight: 500;
  font-size: 1.3rem;
}

.footer-menu-items {
  display: flex;
  flex-direction: column;
  gap: 1.12rem;
}

.footer-menu-items a {
  padding: 0.2rem 0rem;
  color: var(--primary-text-color-new);
  font-weight: 400;
}

.footer-socialmedia-icons {
  display: flex;
  justify-content: flex-end;
  gap: 0.6rem;
}

.footer-socialmedia-icons a {
  font-size: 1.6rem;
  color: var(--primary-text-color-new);
}

.footer-socialmedia-icons i {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media screen and (max-width: 1200px) {
  .footer {
    padding: 5rem;
  }
}

@media screen and (max-width: 1100px) {
  .footer-address-text {
    padding-right: 9rem;
  }

  .footer-container {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 950px) {
  .footer-address-text {
    padding-right: 7rem;
  }

  .footer-container {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 850px) {
  .footer {
    padding: 2rem 3rem;
  }

  .footer-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .footer-menu-container {
    padding-bottom: 2rem;
  }

  .footer-address-text {
    padding-right: 0rem;
  }

  .footer-menu:nth-child(3) {
    padding-right: 0rem;
  }
}

@media screen and (max-width: 800px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    gap: 1.68rem;
  }

  .footer-address-text {
    font-size: 1rem;
    display: flex;
    margin-top: 0.5rem;
  }

  .footer-menu-items {
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .footer {
    padding: 2.25rem 1rem;
  }

  .footer-container {
    display: inline;
  }

  .footer-address-container {
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-start;
  }

  .footer-menu-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
  }

  .footer-address-Image {
    display: inline-block;
  }

  .footer-address-text {
    /* margin-top: 1rem; */
    font-size: 0.875rem;
    display: inline-block;
    margin-left: 1rem;
    margin-top: 0;
  }

  .footer-menu-heading {
    font-size: 1rem;
  }

  .footer-menu-items {
    font-size: 0.875rem;
    gap: 0.75rem;
  }

  .footer-socialmedia-icons {
    font-size: 0.875rem;
    justify-content: flex-start;
  }
}
