.homepageHeadings {
  font-family: var(--new-primary-font);
  line-height: normal;
  font-style: normal;
  font-size: 2.5rem;
  font-weight: 500;
  color: var(--dark-text-primary, #fff);
}

.abhisharSectionHome {
  display: flex;
  padding: 3rem 0.625rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.25rem;
  background: linear-gradient(180deg, #243b55 0%, #141e30 100%);
}

.abhisharCardsContainerHome {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 2rem;
  width: 70rem;
  justify-content: space-between;
}

.customButton {
  color: var(--primary-accent-color);
  font-size: 1.25rem;
  font-weight: 600;
}

@media screen and (max-width: 1120px) {
  .abhisharCardsContainerHome {
    width: 60rem;
    gap: 1.2rem;
  }
}

@media screen and (max-width: 998px) {
  .abhisharCardsContainerHome {
    width: 46rem;
    flex-wrap: wrap;
    padding-left: 1rem;
    gap: 0.9rem;
  }
}

@media screen and (max-width: 798px) {
  .abhisharCardsContainerHome {
    width: 37rem;
    padding-left: 3.3rem;
  }
}

@media screen and (max-width: 698px) {
  .abhisharCardsContainerHome {
    width: 30rem;
    padding-left: 0.65rem;
  }
}

@media screen and (max-width: 550px) {
  .abhisharCardsContainerHome {
    width: 20rem;
    padding-left: 3rem;
    gap: 1rem;
  }
}

@media screen and (max-width: 440px) {
  .abhisharCardsContainerHome {
    width: 15rem;
    padding-left: 0.45rem;
  }
}
