.marquee-heading {
  background-color: var(--marquee-bg-color);
  left: calc(50% - 90rem / 2);
  overflow-x: scroll;
  font-family: var(--secondary-font-new);
  height: 3rem;
  overflow-y: hidden;
}

.marquee-items {
  font-size: 1rem;
  line-height: 3rem;
  align-items: center;
  color: var(--marquee-text-color);
  margin: auto 3.12rem;
}

@media screen and (max-width: 600px) {
  .marquee-heading {
    height: 2.375rem;
    left: calc(50% - 22.5rem / 2);
  }

  .marquee-items {
    font-size: 0.75rem;
    line-height: 2.375rem;
    align-items: center;
    color: var(--marquee-text-color);
    margin: auto 1.56rem;
  }
}
