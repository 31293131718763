.customButton {
  text-decoration-line: none;
  cursor: pointer;
  transition: 0.2s;
  color: var(--primary-accent-color);
}

.customButton:hover {
  color: var(--primary-text-color);
}
