.abhisharpage-section {
  background: var(--surface-dark-primary, #ffffff);
  display: flex;
  justify-content: space-around;
  padding: 0rem 5rem;
  padding-top: 0rem;
  font-size: 1.55rem;
  height: 94vh;
}

.abhisharpage-content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 47rem;
}

.abhisharpage-headings {
  padding: 0rem 2rem;
  font-size: 3rem;
  color: var(--text-primary, #e5e5e5);
}

.abhisharpage-text {
  padding: 2rem;
  color: var(--text-primary, #e5e5e5);
  align-self: stretch;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.abhisharpage-image {
  justify-content: center;
  align-items: center;
  display: flex;
  background: var(--surface-dark-primary, #02071b);
  color: var(--text-primary, #e5e5e5);
  padding: 1rem;
  text-align: center;
  gap: 2rem;
}

.abhisharpage-image img {
  object-position: center;
  padding: 1rem;
}

.abhisharpage-card-container {
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #243b55 0%, #141e30 100%);
  padding: 1rem 0rem;
  align-items: flex-start;
  align-content: flex-start;
  gap: 5.5rem;
  flex-wrap: wrap;
}

.previous-abhishar-container {
  background: linear-gradient(180deg, #243b55 0%, #141e30 100%);
  padding-bottom: 2rem;
}
.all-abhishars {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 3rem;
  padding: 2rem 0rem;
  color: var(--text-primary, #e5e5e5);
}

.abhishar-pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.abhishar-pagination button {
  border: none;
  background-color: #f0f0f0;
  color: #333;
  padding: 8px 14px;
  margin: 0 0.8rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.abhishar-pagination button.active {
  background-color: #333;
  color: #fff;
}

.abhishar-container {
  display: inline-flex;
  padding: 2rem 6rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}
.abhishar-container.abhishar-container-mobile .events {
  display: flex;
  flex-direction: column;
}

.abhishar-container.abhishar-container-mobile .abhishar-pagination {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1300px) {
  .abhisharpage-section {
    font-size: 1.3rem;
    padding: 0rem 3.5rem;
  }

  .abhisharpage-headings {
    font-size: 2.7rem;
  }

  .abhisharpage-image img {
    height: 18rem;
    width: 12rem;
  }
}

@media screen and (max-width: 1100px) {
  .abhisharpage-section {
    font-size: 1.2rem;
    padding: 0rem 2.75rem;
  }

  .abhisharpage-headings {
    font-size: 2.5rem;
  }

  .abhisharpage-image img {
    height: 15rem;
    width: 10rem;
  }
}

@media screen and (max-width: 960px) {
  .abhisharpage-section {
    font-size: 1rem;
    padding: 0rem 2.5rem;
  }

  .abhisharpage-headings {
    font-size: 2rem;
  }

  .abhisharpage-image img {
    height: 15rem;
    width: 10rem;
  }
}

@media screen and (max-width: 715px) {
  .abhisharpage-section {
    font-size: 1rem;
    padding: 0rem 2.5rem;
    flex-direction: column-reverse;
  }

  .abhisharpage-headings {
    font-size: 2rem;
  }

  .abhisharpage-image {
    display: flex;
    justify-content: center;
  }
  .abhisharpage-image img {
    height: 15rem;
    width: 10rem;
  }
}

@media screen and (max-width: 500px) {
  .abhisharpage-section {
    font-size: 0.7rem;
    padding: 0rem 1.2rem;
    display: flex;
    justify-content: center;
  }

  .abhisharpage-headings {
    font-size: 2rem;
  }

  .abhisharpage-image img {
    height: 9.6rem;
    width: 8rem;
  }
  .abhisharpage-image {
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .all-abhishars {
    font-size: 2rem;
    padding-bottom: 0rem;
  }
  .abhishar-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 1rem;
  }
  .abhisharpage-text {
    font-size: 1rem;
  }
  .abhisharpage-card-container {
    gap: 2.5rem;
  }
}
