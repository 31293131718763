.contact-us-page-innerbox {
  background-color: var(--primary-bg-color-new);
  border-radius: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-family: var(--secondary-font-new);
  padding: 2.25rem;
  gap: 2.25rem;
}

.Form-details {
  padding: 0;
}
.Contact-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Contact-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.625rem;
}
.Contact-details-item {
  color: var(--secondary-text-color-new);
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  font-weight: 500;
}

@media screen and (max-width: 1300px) {
  .Contact-image {
    display: none;
  }

  .contact-us-page-innerbox {
    display: flex;
    flex-direction: column;
  }

  .Contact-items {
    padding: 0 1.5rem 0 1.7rem;
    margin-bottom: 2.5rem;
    gap: 1.56rem;
  }

  .Contact-details-item {
    gap: 1.56rem;
  }
}

@media screen and (max-width: 960px) {
  .contact-us-page-innerbox {
    margin: 4rem 10rem;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  .contact-us-page-innerbox {
    margin: 0;
    padding: 0 1.5rem;
    font-size: 1.2rem;
  }
}
