.construction {
  background-color: var(--primary-bg-color);
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.construction-image img {
  height: 50vh;
}

.construction-text {
  font-size: 1.5rem;
  padding: 1rem;
}
