.abhisharCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.875rem;
  width: 16rem;
}

.abhisharCoverImage {
  width: 16rem;
  height: 20.6818rem;
  flex-shrink: 0;
}

.abhisharCardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 0 0;
}

.abhisharCardText {
  color: var(--dark-text-primary);
  font-weight: 400;
  font-style: normal;
}

.abhisharVersion {
  font-family: var(--new-primary-font);
  font-size: 1rem;
  line-height: 1.5rem;
  opacity: 0.699999988079071;
}

.abhisharCardHeading {
  font-family: var(--new-primary-font);
  font-size: 1.25rem;
  line-height: normal;
  height: 3.75rem;
}

.abhisharReadLink {
  color: var(--primary-accent-color);
  font-size: 1rem;
  font-weight: 200;
}

.abhisharReadLink:hover {
  color: var(--primary-text-color);
}

@media screen and (max-width: 1120px) {
  .abhisharCard {
    width: 14rem;
  }

  .abhisharCoverImage {
    width: 14rem;
    height: 18.096rem;
  }

  .abhisharCardHeading {
    font-size: 1.1rem;
    height: 3.125rem;
  }
}

@media screen and (max-width: 500px) {
  .abhisharCoverImage {
    width: 14rem;
    height: 18.096rem;
    flex-shrink: 0;
  }

  .abhisharCardHeading {
    height: auto;
  }
}
