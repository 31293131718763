.contact-us-form-container {
  background-color: var(---now-bg-color);
  border-radius: 0.937rem;
  font-size: 1.25rem;
}
.contact-us-title-heading {
  font-family: var(--secondary-font-new);
  font-style: normal;
  font-weight: 500;
  font-size: 2.5rem;
  color: var(--heading-text-color-new);
}

.contact-us-title-motto {
  font-size: 1rem;
  font-weight: 400;
}
.contact-us-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.contact-us-form input,
.contact-us-form textarea {
  color: var(--input-text-color-new);
  outline: none;
  padding: 0.75rem 1rem;
  font-family: var(--secondary-font-new);
  height: 3rem;
  border: none;
  font-size: 0.875rem;
  background: var(--secondary-bg-color-new);
  border-radius: 0.25rem;
}

.contact-us-form textarea {
  height: 12rem;
}

.contact-us-form input:active {
  border: none;
}

.contact-us-form input[type='file'],
.contact-us-form input[type='submit'] {
  visibility: hidden;
}

.upload-button {
  cursor: pointer;
  padding-top: 2rem;
}

.upload-button-text {
  padding: 0 1rem;
  transition: 0.5s;
}

.upload-button-text:hover {
  background-color: var(--primary-accent-color);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: var(--primary-bg-color);
}

.contact-form-footer {
  display: flex;
}

.contact-submit {
  width: 100%;
  height: 3rem;
}
.send-message-button {
  font-family: 'Poppins';
  font-size: 1rem;
  color: #1d1934;
  font-weight: 500;
  line-height: 3rem;
  cursor: pointer;
  background-color: #ffcd25;
  border: 0.0625rem solid #ffcd25;
  border-radius: 0.375rem;
  text-align: center;
}

@media screen and (max-width: 1300px) {
  .contact-us-form input,
  .contact-us-form textarea {
    font-size: 1rem;
  }
}

@media screen and (max-width: 960px) {
  .contact-us-form-container {
    padding-top: 1rem;
  }
  .contact-us-form input,
  .contact-us-form textarea {
    font-size: 1.2rem;
    margin-bottom: 3rem;
  }

  .contact-us-form {
    gap: 0;
  }
}

@media screen and (max-width: 600px) {
  .contact-us-title-heading {
    font-size: 2rem;
  }
  .contact-us-title-motto {
    font-size: 1rem;
  }
  .contact-us-form-container {
    padding: 2.5rem 0 0;
  }
  .contact-us-form input,
  .contact-us-form textarea {
    font-size: 0.875rem;
    margin-bottom: 1rem;
    height: 2.75rem;
  }
  .contact-us-form textarea {
    height: 12rem;
  }

  .contact-us-form {
    gap: 0.625rem;
  }
}
