.carousel .thumbs-wrapper {
  margin: 0;
}

.event-header-container {
  height: 100vh;
  display: flex;
}

.eventFirst,
.eventSecond,
.eventThird,
.eventForth {
  background-color: var(--surface-dark-primary, #02071b);
  display: flex;
  width: 5rem;
  border-right: 0.25rem solid var(--grey-white, #fff);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.eventFirst {
  border-left: 0.25rem solid var(--grey-white, #fff);
}

.imageDiv {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.imageDiv .active h1 {
  height: 34.125rem;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: var(--grey-white, #fff);
  font-family: var(--secondary-font-new);
  font-size: 2.5rem;
  display: inline-block;
}

.imageDiv .active {
  width: 5rem;
}
.imageDiv .eventPic {
  border-right: 0.25rem solid var(--grey-white, #fff);
}
.imageDiv .eventPic img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.imageDiv .eventPic::before {
  content: '';
  position: absolute;
  top: 2.8125rem;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 2.375rem 0 2.375rem 2.0625rem;
  border-color: transparent transparent transparent
    var(--surface-dark-primary, #02071b);
}

@media screen and (max-width: 600px) {
  .event-header-container {
    width: 100%;
    height: 64.625rem;
    display: flex;
    flex-direction: column-reverse;
  }
  .eventFirst,
  .eventSecond,
  .eventThird,
  .eventForth {
    width: 100%;
    border-top: 0.25rem solid var(--grey-white, #fff);
    border-right: 0;
    border-left: 0;
  }
  .eventFirst h1,
  .eventSecond h1,
  .eventThird h1,
  .eventForth h1 {
    writing-mode: horizontal-tb;
    white-space: nowrap;
  }
  .imageDiv {
    flex-direction: column-reverse;
  }

  .imageDiv .active {
    width: 100%;
  }

  .imageDiv .active h1 {
    writing-mode: horizontal-tb;
    white-space: nowrap;
    height: 3.5rem;
  }
  .imageDiv .eventPic {
    border-right: 0;
    height: 100%;
    border-top: 0.25rem solid var(--grey-white, #fff);
  }
  .imageDiv .eventPic::before {
    content: '';
    position: absolute;
    top: 47.36rem;
    left: 2.8125rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1.75rem 1.75rem 1.75rem;
    border-color: transparent transparent var(--surface-dark-primary, #02071b)
      transparent;
  }
}
