.team-page-container {
  background-color: var(--new-primary-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: none;
  gap: 0.625rem;
  padding: 2rem 0rem;
}

.team-container {
  flex-direction: row;
  align-items: center;
  height: auto;
  padding: 0rem 5rem;
  gap: 2rem;
}

.team-page-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 40rem;
  gap: 2rem;
}

.team-header-text {
  font-style: normal;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 3.75rem;
  color: var(--intro-text-color);
}

.team-text {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--body-text-color);
}

@media only screen and(max-width: 1200px) {
  .team-page-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 1.5rem;
  }

  .team-container {
    width: 46.875;
    height: 22.375rem;
  }

  .team-page-text-container {
    width: 20.5rem;
    height: 12rem;
  }
}

@media only screen and (max-width: 768px) {
  .team-header-text {
    font-size: 1.5rem;
  }

  .team-page-container {
    width: 100vw;
    padding: 0rem;
  }

  .team-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0rem;
    margin: 0rem 2rem;
  }

  .team-page-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.8rem;
    /* padding-top: 3rem; */
    gap: 1.5rem;
  }

  .team-header-text {
    font-size: 2rem;
    width: 20.5rem;
    height: 3rem;
  }

  .team-text {
    font-size: 0.9rem;
    width: 20.5rem;
    height: 12rem;
  }
}

@media only screen and (max-width: 350px) {
  .team-container {
    padding: 3rem 0rem;
    gap: 1rem;
  }

  .team-page-text-container {
    width: 12rem;
    height: 60vh;
    gap: 0.6rem;
    margin-right: 1rem;
  }
  .team-header-text {
    font-size: 1.3rem;
    width: 14rem;
  }
  .team-text {
    font-size: 0.7rem;
    width: 14rem;
  }
}
