.navbar-container {
  position: sticky;
  top: 0;
  z-index: 20;
  overflow: hidden;
}

.contact-button,
.contact-button-mobile {
  background-color: var(--primary-accent-color);
  padding: 0.75rem 2rem;
  border-radius: 0.4rem;
  width: fit-content;
  cursor: pointer;
  transition: all ease 0.5s;
  font-size: 1rem;
  color: var(--primary-bg-color);
  font-weight: 500;
}

.contact-button:hover {
  background-color: var(--new-primary-bg-color);
  border: 1.333px solid var(--dark-text-navbar, #f4f4f5);
  color: var(--primary-text-color);
}

.contact-button-mobile {
  display: none;
}

.navbar-opaque {
  background-color: var(--new-primary-bg-color);
}

.navbar-backdrop {
  background-color: var(--navbar-backdrop);
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 20px 4px #000000;
}

.navbar {
  height: 4.5rem;
  font-size: 1.125rem;
  width: 100vw;
  overflow: none;
}

.nav-container {
  width: 100%;
  height: 4.5rem;
  /* max-width: 93.75rem; */
  max-width: 110rem;
}

.navbar-main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.nav-logo {
  align-items: center;
  margin-left: 1.25rem;
  transform: translate(0, 0.4rem);
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.nav-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 1.2rem;
}
.close-button {
  display: none;
}
.nav-item {
  padding: 0.5rem 1rem;
  border-bottom: 0.2rem solid transparent;
  /* line-height: 2rem; */
}

.nav-item:after {
  content: '';
  display: block;
  height: 0.2rem;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: var(--primary-accent-color);
}

.nav-icon {
  display: none;
}

.navbar-active {
  color: var(--primary-accent-color);
  font-weight: 600;
  display: flex;
}
.mobile {
  width: 3rem;
  height: 3rem;
}
.contact-button:active {
  transform: scale(0.8);
}
@media screen and (max-width: 960px) {
  .nav-items-container {
    height: 100vh;
    display: flex;
  }
  .contact-button-mobile {
    background-color: var(--primary-accent-color);
    padding: 0.75rem 2rem;
    border-radius: 0.4rem;
    width: fit-content;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: block;
  }

  .desktop {
    display: none;
  }

  .navbar {
    height: 3.5rem;
  }

  .nav-container {
    height: 3rem;
  }

  .nav-logo {
    position: absolute;
    left: 5%;
  }

  .mobile {
    position: absolute;
    left: 0;
    top: 0;
    width: 27.81px;
    height: 32px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 94vw;
    height: 45vh;
    position: absolute;
    top: 3.5rem;
    top: -2000%;
    opacity: 1;
    transition: all 1s ease;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
  }
  .contact-button {
    display: none;
  }
  .nav-menu.navbar-active {
    background: var(--new-primary-bg-color);
    top: 0px;
    padding: 0.8rem;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    overflow-y: hidden;
    overflow-x: hidden;
    gap: 0.4rem;
    padding-top: 2.5rem;
  }

  .nav-item {
    display: table;
    padding: 0;
    font-size: 0.9rem;
  }
  .contact-button-mobile:active {
    transform: scale(0.9);
  }
  .close-button {
    display: block;
    border-radius: 10.895px;
    padding: 0.25rem 0.45rem;
    margin-top: 1rem;
    border: 1.333px solid var(--dark-text-navbar, #f4f4f5);
  }
  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 45%);
    font-size: 1.5rem;
    cursor: pointer;
  }
  .navbar-container {
    overflow: visible;
  }
}
