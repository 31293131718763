.toTopButton {
  position: fixed;
  height: 3rem;
  width: 3rem;
  bottom: 2rem;
  right: 2rem;
  z-index: 99;
  border-radius: 50%;
  background-color: var(--primary-accent-color);
  cursor: pointer;
  padding: 0.9rem;
  transition: 0.4s;
}

.toTopButton:hover {
  background-color: var(--primary-text-color);
}

.hide-top-button {
  display: none;
}
